/*
Custom scripts
*/
jQuery(document).ready(function($) {

	// This function add top padding to #content on small devices where the homepage slider text 
	// appears below the slider (position: absolute)
	$.fn.setQuoteHeight = function()
		{
			if ($('#n2-ss-1-align .n2-ss-widget>div.n2-ow').css('position') === 'absolute') {
				var $sliderQuoteHeight = $('#n2-ss-1-align .n2-ss-widget>div.n2-ow').outerHeight();
				$(".home.page #content").css("padding-top",$sliderQuoteHeight);
			} else {
				$(".home.page #content").css("padding-top","0");
			}		
		};
	// call the function on document ready
	$.fn.setQuoteHeight();
	// if window is resized, call the function again but delay 100ms
	function resizedw(){
		$.fn.setQuoteHeight();
		// also hide the menu on resize
		if ($('#top-bar-menu').not(':visible')) {
			$hamburger.removeClass("is-active");
		}
		if ($(window).width() < 1024) {
			$('.home.page #bgmp_map-canvas').css('height', '400px');
		} else {
			$('.home.page #bgmp_map-canvas').css('height', '100%');
		}
		
	}
	var doit;
	window.onresize = function(){
	  clearTimeout(doit);
	  doit = setTimeout(resizedw, 100);
	};
	
	//show menu after page loaded
    $(window).load(function(){
		//setTimeout(function() {
			$('.top-bar .top-bar-right').show();
		//}, 100);
    });
	
	//hide menu search form
	$("#menu-search").hide();
	//hide menu and search box if open and user clicked anywhere but the search form
	$(document).on('click', function (e) {
		if ($('#top-bar-menu').is(':visible') && $(e.target).closest("#top-bar-menu").length === 0 && $(e.target).closest(".hamburger").length === 0) {						
			$( '.hamburger' ).click();
			if ($('#menu-search').is(':visible') && $(e.target).closest("#menu-search").length === 0) {
				$( '#menu-search .close-button' ).click();
				$searchicon.removeClass("is-active");
			}	
		}
	});

	// add class to search icon on click
	var $searchicon = $("#top-bar-menu .search.icon a");
		$searchicon.on("click", function(e) {
		if ($('#menu-search').is(':visible') ) {
			$searchicon.removeClass("is-active");
		} else {
			$searchicon.addClass("is-active");			
		}
	});
	
	// remove class on search icon when search box closed
	var $searchclose = $("#menu-search .close-button");
		$searchclose.on("click", function(e) {
			$searchicon.removeClass("is-active");
	});
	
	// trigger hamburger animation
	var $hamburger = $(".hamburger");
		$hamburger.on("click", function(e) {
			$hamburger.toggleClass("is-active");
			$( '#menu-search' ).hide();
			$searchicon.removeClass("is-active");
	});
	
	//set google map to responsive
	$('#bgmp_map-canvas').css('width', '100%');
	
	// add placeholder text for IE8+
	$('[placeholder]').focus(function() {
	  var input = $(this);
	  if (input.val() == input.attr('placeholder')) {
		input.val('');
		input.removeClass('placeholder');
	  }
	}).blur(function() {
	  var input = $(this);
	  if (input.val() == '' || input.val() == input.attr('placeholder')) {
		input.addClass('placeholder');
		input.val(input.attr('placeholder'));
	  }
	}).blur();
	// strip placeholder text before submitting
	$('[placeholder]').parents('form').submit(function() {
	  $(this).find('[placeholder]').each(function() {
		var input = $(this);
		if (input.val() == input.attr('placeholder')) {
		  input.val('');
		}
	  });
	});

});